import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "name": "Business model",
  "route": "/business-model"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Business model`}</h1>
    <h2>{`Zero exit`}</h2>
    <p>{`Smartlike developers seek to cover development costs with donations and plan to make a zero exit to community when the development is finished:`}</p>
    <ul>
      <li parentName="ul">{`Users who want to support platform development add funds via Smartlike, providing resources to complete technical decentralization.`}</li>
      <li parentName="ul">{`Micro-donations/subscriptions that come later will balance the negative amount on Smartlike's account enabling a zero exit to community - when development is completed Smartlike gives the control over to the community and closes its account with zero balance.`}</li>
    </ul>
    <h2>{`Roadmap`}</h2>
    <p>{`2022 - Technical decentralization`}</p>
    <ul>
      <li parentName="ul">{`decentralized network development`}</li>
      <li parentName="ul">{`beta fixes and algorithm tuning`}</li>
    </ul>
    <p>{`2023 - Exit to community`}</p>
    <ul>
      <li parentName="ul">{`switch to a DHT network`}</li>
      <li parentName="ul">{`hand over the control to a meritocratic community governance (users who benefit most should drive the system)`}</li>
      <li parentName="ul">{`a zero exit`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      